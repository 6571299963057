import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Link from "@mui/material/Link";

// Assets
import bpnLogo from "../assets/bpn.png";
import { Container } from "@mui/material";

export default function AppBarComponents() {
  return (
    <AppBar position="static" sx={{ boxShadow: "none", background: "#fff" }}>
      <Container maxWidth="lg">
        <Toolbar sx={{ pt: 1.5, pb: 1.5 }}>
          <Link href="/">
            <img
              src={bpnLogo}
              alt="BPN"
              width="60"
              style={{ marginRight: 12 }}
            />
          </Link>
          <nav>
            <Link
              variant="button"
              color="text.primary"
              href="/"
              sx={{
                my: 1,
                mx: 1.5,
                textTransform: "none",
                textDecoration: "none",
              }}
            >
              <b>Dashboard</b>
            </Link>
            <Link
              variant="button"
              color="text.primary"
              href="#"
              sx={{
                my: 1,
                mx: 1.5,
                textTransform: "none",
                textDecoration: "none",
              }}
            >
              Document History
            </Link>
          </nav>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
