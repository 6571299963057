import * as React from "react";
import Button from "@mui/material/Button";

import {
  Document,
  Page,
  // View,
  Text,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import { PDFDownloadLink } from "@react-pdf/renderer";

// Assets
import bpnLogo from "../assets/bpn.png";
import garudaLogo from "../assets/garuda.png";
import qrcode from "../assets/qr.png";

function GeneratePDF({ ...props }) {
  const file = props.file;
  const latestOwner = file.rawHakAtasTanah[1];

  // Create styles
  const styles = StyleSheet.create({
    page: {
      backgroundColor: "#FFFFFF",
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    parent: {
      flexDirection: "row",
      paddingBottom: 10,
    },
    imageBpn: {
      marginVertical: 15,
      marginHorizontal: 232,
      width: "10%",
      marginBottom: 10,
    },
    imageGaruda: {
      marginVertical: 15,
      marginHorizontal: 190,
      width: "28%",
      marginBottom: 60,
    },
    imageQr: {
      marginVertical: 15,
      marginHorizontal: 225,
      width: "16%",
      marginBottom: 10,
    },
    titleHeader: {
      fontSize: 20,
      textAlign: "center",
      marginBottom: 10,
      fontWeight: "bold",
      color: "#003E69",
    },
    subTitleHeader: {
      fontSize: 12,
      textAlign: "center",
      marginBottom: 20,
      color: "#767676",
    },
    title: {
      fontSize: 12,
      textAlign: "center",
      marginBottom: 10,
      fontWeight: "bold",
      color: "#767676",
    },
    textValue: {
      fontSize: 15,
      textAlign: "center",
      marginBottom: 15,
      fontWeight: "bold",
      color: "#003E69",
    },
    textNumber: {
      fontSize: 12,
      textAlign: "center",
      marginBottom: 30,
      fontWeight: "bold",
      color: "#003E69",
    },
  });
  // Register font
  Font.register({
    family: "Montserrat",
    fonts: [
      {
        src: "https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-Y3tcoqK5.ttf",
      },
      {
        src: "https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCu170w-Y3tcoqK5.ttf",
        fontWeight: "600",
      },
    ],
  });
  // Create Document Component
  const LandCertificateDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image style={styles.imageBpn} src={bpnLogo} />
        <Text style={styles.titleHeader}>SERTIFIKAT KEPEMILIKAN TANAH</Text>
        <Text style={styles.subTitleHeader}>Diterbitkan oleh BPN/ATR</Text>
        <Image style={styles.imageGaruda} src={garudaLogo} />
        <Text style={styles.title}>Nama Pemilik</Text>
        <Text style={styles.textValue}>{latestOwner.RAWPEMILIK[0].NAMA}</Text>
        <Text style={styles.title}>Nomor Induk Kependudukan</Text>
        <Text style={styles.textValue}>
          {latestOwner.RAWPEMILIK[0].NOMORIDENTITAS === null
            ? "-"
            : latestOwner.RAWPEMILIK[0].NOMORIDENTITAS}
        </Text>
        <Text style={styles.title}>Alamat</Text>
        <Text style={styles.textValue}>
          {" "}
          {latestOwner.RAWPEMILIK[0].ALAMAT === null
            ? "-"
            : latestOwner.RAWPEMILIK[0].ALAMAT}
        </Text>
        <Text style={styles.textNumber}>
          Luas Tertulis: 639.0; Luas Terhitung: 641.605; Tanggal Validasi:
          23-07-2021
        </Text>
        <Image style={styles.imageQr} src={qrcode} />
        <Text style={styles.title}>Sertifikat Tanah Elektronik ini dikeluarkan oleh BPN/ATR</Text>
      </Page>
    </Document>
  );

  return (
    <PDFDownloadLink
      document={<LandCertificateDocument />}
      fileName={
        latestOwner.RAWPEMILIK[0].NAMA + "_digital-land-certificate.pdf"
      }
      style={{ textDecoration: "none" }}
    >
      {({ blob, url, loading, error }) =>
        loading ? (
          "Loading document..."
        ) : (
          <Button
            variant="contained"
            component="label"
            sx={{
              backgroundColor: "#003E69",
              textTransform: "none",
              boxShadow: "none",
              letterSpacing: "-0.2px",
              fontWeight: "bold",
            }}
          >
            Generate Digital Land Certificate
          </Button>
        )
      }
    </PDFDownloadLink>
  );
}

export default GeneratePDF;
