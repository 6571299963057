import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import Chip from "@mui/material/Chip";

// Assets
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

// Data
import Data1 from "../data/land-certificate-1.json";
import Data2 from "../data/land-certificate-2.json";
import Data3 from "../data/land-certificate-3.json";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#003E69",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(name, doc_id, doc_date, doc_status, doc_data) {
  return { name, doc_id, doc_date, doc_status, doc_data };
}

const rows = [
  createData("Ir. Suyus Windayana", "A0001", "01/11/2023", "Issued", Data1),
  createData("Ir. Suyus Windayana", "A0002", "01/11/2023", "Not Issued", Data2),
  createData(
    "PT. Kukuh Mandiri Lestari",
    "A0003",
    "02/11/2023",
    "Not Issued",
    Data3
  ),
];

export default function CustomizedTables() {
  const navigate = useNavigate();

  const showDetailData = (e) => {
    navigate("/view", { state: e });
  };

  return (
    <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Fullname</StyledTableCell>
            <StyledTableCell align="left">Document ID</StyledTableCell>
            <StyledTableCell align="left">Document Date</StyledTableCell>
            <StyledTableCell align="left">Document Status</StyledTableCell>
            <StyledTableCell align="center">Action</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.doc_id}>
              <StyledTableCell component="th" scope="row">
                <b>{row.name}</b>
              </StyledTableCell>
              <StyledTableCell align="left">{row.doc_id}</StyledTableCell>
              <StyledTableCell align="left">{row.doc_date}</StyledTableCell>
              <StyledTableCell align="left">
                <Chip
                  label={row.doc_status}
                  color={row.doc_status === "Issued" ? "success" : "error"}
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <div>
                  <IconButton
                    color="text.secondary"
                    onClick={(e) => showDetailData(row.doc_data)}
                  >
                    <VisibilityOutlinedIcon />
                  </IconButton>
                </div>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
