import * as React from "react";
import Typography from "@mui/material/Typography";

// Components
import GeneratePDf from "./GeneratePDF";

export default function Title({ ...props }) {
  return props.generatePDF ? (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      <div style={{ alignSelf: "center" }}>
        <Typography fontWeight="bold" sx={{ fontSize: 17, pt: 3.5, pb: 2.5 }}>
          {props.title}
        </Typography>
      </div>
      <div style={{ flexGrow: 1, textAlignLast: "right", alignSelf: "center" }}>
        <GeneratePDf file={props.file} />
      </div>
    </div>
  ) : (
    <Typography fontWeight="bold" sx={{ fontSize: 17, pt: 3.5, pb: 2.5 }}>
      {props.title}
    </Typography>
  );
}
