import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

function BasicCard({ ...props }) {
  const { data, isLong, id } = props;

  return (
    <Card
      sx={{
        display: "flex",
        // boxShadow: "10px 14px 15px -12px rgba(0,0,0,0.1)",
        boxShadow: "none",
        border: "1px solid rgba(38, 38, 38, 0.1)",
        borderRadius: "10px",
      }}
    >
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography variant="subtitle2" color="text.secondary">
              Pemilik {id + 1}
            </Typography>
          </Grid>
          <Grid item xs={12} md={isLong ? 12 : 6}>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ color: "#003E69" }}
            >
              {data.RAWPEMILIK[0].NAMA}
            </Typography>
            <Typography gutterBottom variant="subtitle2" color="text.secondary">
              {data.TIPE}
            </Typography>
          </Grid>
          <Grid item xs={12} md={isLong ? 12 : 6}>
            <Typography gutterBottom variant="caption" color="text.secondary">
              Blockchain Transaction ID:
            </Typography>
            <Typography variant="body2" fontWeight="bold">
              <a
                href="https://testnet.symbol.fyi/mosaics/11821C0CB373E6F2"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: "#003E69",
                  textDecoration: "unset",
                }}
              >
                11821C0CB373E6F2
              </a>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography gutterBottom variant="caption" color="text.secondary">
              NIK:
            </Typography>
            <Typography variant="body2" fontWeight="bold">
              {data.RAWPEMILIK[0].NOMORIDENTITAS === null
                ? "-"
                : data.RAWPEMILIK[0].NOMORIDENTITAS}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography gutterBottom variant="caption" color="text.secondary">
              Jenis Kelamin
            </Typography>
            {data.RAWPEMILIK[0].JENISKELAMIN === "P" ? (
              <Typography variant="body2" fontWeight="bold">
                PEREMPUAN
              </Typography>
            ) : data.RAWPEMILIK[0].JENISKELAMIN === null ? (
              <Typography variant="body2" fontWeight="bold">
                -
              </Typography>
            ) : (
              <Typography variant="body2" fontWeight="bold">
                {data.RAWPEMILIK[0].JENISKELAMIN}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography gutterBottom variant="caption" color="text.secondary">
              Valid Sejak:
            </Typography>
            <Typography variant="body2" fontWeight="bold">
              {data.VALIDSEJAK === null ? "-" : data.VALIDSEJAK?.$date}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography gutterBottom variant="caption" color="text.secondary">
              Valid Sampai:
            </Typography>
            <Typography variant="body2" fontWeight="bold">
              {data.VALIDSAMPAI === null ? "-" : data.VALIDSAMPAI?.$date}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography gutterBottom variant="caption" color="text.secondary">
              ID Hak Atas Tanah:
            </Typography>
            <Typography
              variant="body2"
              fontWeight="bold"
              style={{
                textOverflow: "ellipsis",
                width: isLong ? "100px" : "200px",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {data.HAKATASTANAHID === null ? "-" : data.HAKATASTANAHID}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography gutterBottom variant="caption" color="text.secondary">
              Pemilik ID
            </Typography>
            <Typography
              variant="body2"
              fontWeight="bold"
              style={{
                textOverflow: "ellipsis",
                width: isLong ? "100px" : "200px",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {data.PEMILIKID === null ? "-" : data.PEMILIKID}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default function LandOwnershipHistory({ ...props }) {
  const data = props.file;

  return (
    <Grid container spacing={0.5} sx={{ pt: 1 }}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {data.map((x, i) => (
            <Grid item key={i} xs={12} md={12 / data.length}>
              <BasicCard
                data={x}
                id={i}
                isLong={data.length > 2 ? true : false}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
