import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Pages
// import Index from "./pages/Index";
import Dashboard from "./pages/Dashboard";
import ViewDocument from "./pages/ViewDocument";

// Components
import AppBar from "./components/AppBar";
import Footer from "./components/Footer";

const theme = createTheme({
  typography: {
    fontFamily: `"Montserrat", sans-serif`,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    letterSpacing: "-2px",
  },
});

export default function BasicTabs() {
  return (
    <ThemeProvider theme={theme}>
      <AppBar />
      <Router>
        <Routes>
          <Route path="/" element={<Dashboard />} exact></Route>
          {/* <Route path="/dashboard" element={<Dashboard />} exact></Route> */}
          <Route path="/view" element={<ViewDocument />}></Route>
        </Routes>
      </Router>
      <Footer />
    </ThemeProvider>
  );
}
