import * as React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Components
import Title from "../components/Title";
import WalletCard from "../components/WalletCard";
import AccumulativeCard from "../components/AccumulativeCard";
import Datatable from "../components/Datatable";

export default function Pricing() {
  return (
    <div>
      <Container maxWidth="lg" sx={{ pt: 4, minHeight: "100vh" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ pb: 1 }}>
            <WalletCard />
          </Grid>
          <Grid item xs={12}>
            <AccumulativeCard />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Title title={"Document List"} generatePDF={false} />
        </Grid>
        <Grid item xs={12}>
          <Datatable />
        </Grid>
      </Container>
    </div>
  );
}
