import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

function BasicCard({ ...props }) {
  const { data, id } = props;

  return (
    <Card
      sx={{
        display: "flex",
        // boxShadow: "10px 14px 15px -12px rgba(0,0,0,0.1)",
        boxShadow: "none",
        border: "1px solid rgba(38, 38, 38, 0.1)",
        borderRadius: "10px",
      }}
    >
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="text.secondary">
              Transaksi {id + 1}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography gutterBottom variant="caption">
              Kode DI:
            </Typography>
            <Typography variant="body2" fontWeight="bold">
              {data.KODEDI}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography gutterBottom variant="caption">
              Nomor:
            </Typography>
            <Typography variant="body2" fontWeight="bold">
              {data.NOMOR}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography gutterBottom variant="caption">
              Tanggal:
            </Typography>
            <Typography variant="body2" fontWeight="bold">
              {data.TANGGAL === null ? "-" : data.TANGGAL?.$date}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default function ActionAreaCard({ ...props }) {
  const data = props.file;

  return data !== null ? (
    <Grid container spacing={0.5} sx={{ pt: 1 }}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {data.RAWDAFTARISIAN.map((x, i) => (
            <Grid item key={i} xs={12} md={12 / data.RAWDAFTARISIAN.length}>
              <BasicCard data={x} id={i} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  ) : null;
}
