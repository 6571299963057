import * as React from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// Icon
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

// Assets
import wallet from "../assets/wallet.png";
import avatar from "../assets/avatar.png";

function IdentityCard() {
  return (
    <Card
      sx={{
        p: 3,
        boxShadow: "none",
        borderRadius: "10px",
      }}
    >
      <div style={{ display: "flex" }}>
        <div style={{ marginRight: 30 }}>
          <img src={avatar} alt="BPN" width="100" />
        </div>
        <div style={{ alignSelf: "center" }}>
          <Typography variant="h5" sx={{ mb: 0.5 }} fontWeight="bold">
            Hi, Alkautsar Sanusi 👋🏻
          </Typography>
          <Typography sx={{ fontSize: 13, marginBottom: 2 }}>
            Associate Staff Land Certificate
          </Typography>
          <div style={{ display: "flex" }}>
            <div style={{ display: "flex", marginRight: 15 }}>
              <AccountCircleOutlinedIcon
                style={{ fontSize: 20, marginRight: 5 }}
              />
              <Typography variant="caption" color="text.secondary">
                BPN01020
              </Typography>
            </div>
            <div style={{ display: "flex" }}>
              <EmailOutlinedIcon style={{ fontSize: 20, marginRight: 5 }} />
              <Typography variant="caption" color="text.secondary">
                alkautsar@atrbpn.go.id
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}

function WalletCard() {
  return (
    <Card
      sx={{
        p: 3,
        boxShadow: "none",
        borderRadius: "10px",
      }}
    >
      <div style={{ display: "flex" }}>
        <div style={{ marginRight: 15 }}>
          <img src={wallet} alt="BPN" width="100" />
        </div>
        <div style={{ alignSelf: "center" }}>
          <Typography variant="h5" gutterBottom fontWeight="bold">
            500 XYM
          </Typography>
          <Typography variant="body2" gutterBottom color="text.secondary">
            Account Balance
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ fontSize: 10 }}
          >
            Latest Transactions: <b>12/11/2023</b>
          </Typography>
        </div>
      </div>
    </Card>
  );
}

export default function OutlinedCard() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        <IdentityCard />
      </Grid>
      <Grid item xs={12} md={4}>
        <WalletCard />
      </Grid>
    </Grid>
  );
}
