import * as React from "react";
import { MapContainer, TileLayer, Polyline } from "react-leaflet";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

function GeneratedMap({ ...props }) {
  const location = props.file;

  const redColor = { color: "red" };
  const center = [-6.905977, 107.613144];

  return (
    <Grid container spacing={0.5}>
      <Grid item xs={12} md={12}>
        <Card
          sx={{
            // boxShadow: "10px 14px 15px -12px rgba(0,0,0,0.1)",
            boxShadow: "none",
            border: "1px solid rgba(38, 38, 38, 0.1)",
            borderRadius: "10px",
          }}
        >
          <MapContainer
            center={location[0] || center}
            zoom={25}
            scrollWheelZoom={false}
            style={{
              width: "100%",
              height: "52vh",
            }}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            {location ? (
              <Polyline pathOptions={redColor} positions={location} />
            ) : null}
          </MapContainer>
        </Card>
      </Grid>
    </Grid>
  );
}

export default GeneratedMap;
