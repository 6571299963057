import * as React from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { useLocation } from "react-router-dom";

// Components
import Title from "../components/Title";
import WalletCard from "../components/WalletCard";
import OwnerCard from "../components/details/Owner";
import Location from "../components/details/Location";
import TransactionHistory from "../components/details/TransactionHistory";
import LandOwnershipHistory from "../components/details/OwnershipHistory";

function GenerateData() {
  const { state } = useLocation();
  const file = state[0].konten;
  const location = file.rawPersil[0].BATASBIDANG.coordinates[0][0];
  const latestOwner = file.rawHakAtasTanah.at(-1);
  const ownershipHistory = file.rawHakAtasTanah;
  const transactionHistory =
    file.catatanJualBeli === null ? null : file.catatanJualBeli.at(-1);

  return (
    <Container
      component="main"
      sx={{
        paddingTop: "2%",
        paddingBottom: "6%",
      }}
    >
      <Grid container spacing={2}>
        {file ? (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <WalletCard />
              </Grid>
              <Grid item xs={12} md={12}>
                <Title title="Document Detail" generatePDF={true} file={file} />
              </Grid>
              <Grid item xs={12} md={8}>
                <Location file={location} />
              </Grid>
              <Grid item xs={12} md={4}>
                <OwnerCard file={latestOwner} />
              </Grid>
              <Grid item xs={12} md={12}>
                <Title title="Ownership History" />
              </Grid>
              <Grid item xs={12}>
                <LandOwnershipHistory
                  file={ownershipHistory}
                  generatePDF={false}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Title title="Transaction History" />
              </Grid>
              <Grid item xs={12}>
                <TransactionHistory
                  file={transactionHistory}
                  generatePDF={false}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </Container>
  );
}

export default GenerateData;
