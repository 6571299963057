import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

// Assets
import landImg from "../../assets/land.jpg";

export default function ActionAreaCard({ ...props }) {
  const data = props.file;

  return (
    <Grid container spacing={0.5}>
      <Grid item xs={12} md={12}>
        <Card
          sx={{
            // boxShadow: "10px 14px 15px -12px rgba(0,0,0,0.1)",
            boxShadow: "none",
            borderRadius: "10px",
            border: "1px solid rgba(38, 38, 38, 0.1)",
          }}
        >
          <CardMedia
            component="img"
            height="150"
            image={landImg}
            alt="green iguana"
          />
          <CardContent>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ textTransform: "capitalize", color: "#003E69" }}
            >
              {data.RAWPEMILIK[0].NAMA}
            </Typography>
            <Typography gutterBottom variant="subtitle2" color="text.secondary">
              {data.TIPE}
            </Typography>
            <Grid container spacing={2} sx={{ pt: 1 }}>
              <Grid item xs={12} md={6}>
                <Typography
                  gutterBottom
                  variant="caption"
                  color="text.secondary"
                >
                  NIK:
                </Typography>
                <Typography variant="body2" fontWeight="bold">
                  {data.RAWPEMILIK[0].NOMORIDENTITAS === null
                    ? "-"
                    : data.RAWPEMILIK[0].NOMORIDENTITAS}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  gutterBottom
                  variant="caption"
                  color="text.secondary"
                >
                  Jenis Kelamin:
                </Typography>
                {data.RAWPEMILIK[0].JENISKELAMIN === "P" ? (
                  <Typography variant="body2" fontWeight="bold">
                    PEREMPUAN
                  </Typography>
                ) : data.RAWPEMILIK[0].JENISKELAMIN === null ? (
                  <Typography variant="body2" fontWeight="bold">
                    -
                  </Typography>
                ) : (
                  <Typography variant="body2" fontWeight="bold">
                    {data.RAWPEMILIK[0].JENISKELAMIN}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  gutterBottom
                  variant="caption"
                  color="text.secondary"
                >
                  ID Pemilik:
                </Typography>
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  style={{
                    textOverflow: "ellipsis",
                    width: "100px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {data.PEMILIKID === null ? "-" : data.PEMILIKID}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  gutterBottom
                  variant="caption"
                  color="text.secondary"
                >
                  ID Hak Atas Tanah:
                </Typography>
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  style={{
                    textOverflow: "ellipsis",
                    width: "100px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {data.HAKATASTANAHID === null ? "-" : data.HAKATASTANAHID}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  gutterBottom
                  variant="caption"
                  color="text.secondary"
                >
                  Valid Sejak:
                </Typography>
                <Typography variant="body2" fontWeight="bold">
                  {data.VALIDSEJAK === null ? "-" : data.VALIDSEJAK?.$date}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  gutterBottom
                  variant="caption"
                  color="text.secondary"
                >
                  Valid Sampai:
                </Typography>
                <Typography variant="body2" fontWeight="bold">
                  {data.VALIDSAMPAI === null ? "-" : data.VALIDSAMPAI}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
