import * as React from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// Assets
import doc1 from "../assets/doc1.png";
import doc2 from "../assets/doc2.png";
import doc3 from "../assets/doc3.png";

const AccumulativeData = [
  { id: 1, img: doc1, title: "Total Data", amount: 3 },
  { id: 2, img: doc2, title: "Generated Certificate", amount: 1 },
  { id: 2, img: doc3, title: "Ungenerated Certificate", amount: 2 },
];

function AccumulativeCard({ ...props }) {
  return (
    <Card
      sx={{
        p: 2,
        boxShadow: "none",
        borderRadius: "10px",
      }}
    >
      <div style={{ display: "flex", flexWrap: "wrap", marginBottom: 5 }}>
        <div style={{ alignSelf: "center" }}>
          <Typography
            sx={{ fontSize: 13 }}
            gutterBottom
            color="text.secondary"
            fontWeight="medium"
          >
            {props.title}
          </Typography>
        </div>
        <div style={{ flexGrow: 1, textAlignLast: "right" }}>
          <img src={props.img} alt="BPN" width="35" />
        </div>
      </div>
      <Typography variant="h5" fontWeight="bold">
        {props.amount}
      </Typography>
    </Card>
  );
}

export default function OutlinedCard() {
  return (
    <Grid container spacing={2}>
      {AccumulativeData.map((x, i) => (
        <Grid item key={i} xs={12} md={4}>
          <AccumulativeCard
            img={x.img}
            title={x.title}
            amount={x.amount}
            id={x.id}
          />
        </Grid>
      ))}
    </Grid>
  );
}
